import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getDefaultTeam, transformTeam } from '~/services/team.services';

export const useTeamStore = create<any>()(
  persist(
    (set) => ({
      team: {},
      initTeam: async () => {
        const team = await getDefaultTeam();
        set({ team: team ? transformTeam(team) : {} });
        return transformTeam(team);
      },
      setTeam: (team: any) => {
        set({ team: transformTeam(team) });
      },
      setBranding: (branding: any) => {
        set((oldState: { team: any }) => ({ team: { ...oldState.team, branding } }));
      }
    }),
    { name: 'woxo-team', version: 3 }
  )
);
