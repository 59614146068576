import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FeaturePopover } from './FeaturePopover';

import { useStyles } from './styles/PlansDetailsTable.styles';

export type TableFeature = {
  text: string;
  hoverText?: string;
};

export type Row = {
  feature: TableFeature;
  freePlan: JSX.Element | string;
  explorePlan: JSX.Element | string;
  basicPlan: JSX.Element | string;
  proPlan: JSX.Element | string;
};

type PlansDetailsTableProps = {
  header?: string;
  tableHeader?: boolean;
  subHeader?: string;
  subSubHeader?: string;
  rows: Row[];
};

export const PlansDetailsTable = ({
  rows,
  header,
  tableHeader,
  subHeader,
  subSubHeader
}: PlansDetailsTableProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TableContainer>
        <div className={classes.tableContainerText}>
          <h1>{header}</h1>
          <h2>{subHeader}</h2>
          <h3>{subSubHeader}</h3>
        </div>
        <Table size="small">
          {tableHeader ? (
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Features</TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Free
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Explorer
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Basic
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Pro
                </TableCell>
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.feature.text}>
                <TableCell component="th" scope="row" className={classes.tableFeature}>
                  {row.feature.hoverText ? (
                    <FeaturePopover text={row.feature.hoverText}>{row.feature.text}</FeaturePopover>
                  ) : (
                    <span>{row.feature.text}</span>
                  )}
                </TableCell>
                <TableCell align="center">{row.freePlan}</TableCell>
                <TableCell align="center">{row.explorePlan}</TableCell>
                <TableCell align="center">{row.basicPlan}</TableCell>
                <TableCell align="center">{row.proPlan}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
