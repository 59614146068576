import type { PlanType } from '../index';

export const plansColors: Record<
  PlanType,
  { primary: string; accent: string; hover: string; font: string; link?: string }
> = {
  Free: { primary: '#7e8082', accent: '#7e8082', hover: '#121212a5', font: '#fff' },
  Explorer: { primary: '#414042', accent: '#414042', hover: '#252525', font: '#fff' },
  Basic: { primary: '#7C11F1', accent: '#7C11F1', hover: '#5010a0', font: '#fff' },
  Pro: {
    primary: '#0086ff',
    accent: '#0086ff',
    hover: '#006fff',
    font: '#fff'
  },
  Business: { primary: '#5BC005', accent: '#5BC005', hover: '#3b7f01', font: '#fff' }
};
